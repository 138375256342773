<template>
  <div class="flex flex-col items-center justify-center">
    <div class="auth-width">
      <router-view />
    </div>
  </div>
</template>

<script>
import env from '@/config/env';

export default {
  computed: {
    name() {
      return env.name;
    },
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.auth-width {
  width: 320px;
}
</style>
