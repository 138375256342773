const suppprtDarkMode =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export default {
  name: process.env.VUE_APP_NAME,
  isDev: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',

  timeFormat: 'hh:mm A',
  dateFormat: 'ddd DD MMMM YYYY',
  dateTimeFormat: 'ddd DD MMMM YYYY hh:mm A',

  darkModeSupport: suppprtDarkMode,
  defaultTheme: suppprtDarkMode ? 'dark' : 'light',
};
